@keyframes dashedBorderMove {
  100% {
    background-position: 4px 0, -4px 100%, 0 -4px, 100% 4px;
  }
}

.project-main-container {
  max-width: 60rem;
  padding: 2rem;

  .project-title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .gray-text {
    color: gray;
  }

  .page-title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 5% 0;
  }

  a {
    text-decoration: none;
  }

  .show-button {
    cursor: pointer;
  }

  .change-color:hover {
    color: #4a78e3;
    font-weight: 700;
  }

  .close-button {
    background-color: orange;
    color: white;
    border: none;
    font-size: large;
    width: 2rem;
    height: 2rem;
  }

  .hide-box {
    display: none;
    z-index: 100;
  }

  // 첫번째 section
  #project-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 30%;
    }

    .roll,
    h1 {
      font-weight: 700;
      text-align: center;
    }

    .project-image {
      width: 100%;
    }

    .erd-title {
      cursor: pointer;
      color: #551a8b;
    }

    .erd-title:hover {
      color: #4a78e3;
    }
  }

  // 두번째 section - main
  #main-page {
    position: relative;

    .page-introduce {
      position: relative;
      display: flex;

      .main-image {
        width: 60%;
        position: relative;
        z-index: 1;
      }

      .top10 {
        width: 98%;
        height: 75%;
        position: absolute;
        top: 19.5%;
        left: 1%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        z-index: 0;
      }

      .red-box {
        width: 60%;
        height: 16%;
        position: absolute;
        top: 22%;
        cursor: pointer;
        z-index: 1;

        background: linear-gradient(90deg, red 50%, transparent 50%) 0 0 / 5px
            2px repeat-x,
          linear-gradient(90deg, red 50%, transparent 50%) 0 100% / 5px 2px
            repeat-x,
          linear-gradient(0, red 50%, transparent 50%) 0 0 / 2px 5px repeat-y,
          linear-gradient(0, red 50%, transparent 50%) 100% 0 / 2px 5px repeat-y;

        animation: dashedBorderMove 0.8s infinite linear;

        &:hover .top10 {
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }
      }
    }

    // 반응형 메인
    .responsive-main-box {
      background-color: white;
      width: 60%;
      position: absolute;
      top: 0;
      left: 0;

      .responsive-main {
        height: 100%;
        width: 38.5%;
      }
    }
  }

  .text-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 20rem;
    background-color: rgb(197, 155, 155);
  }

  // 세번째 section
  #ani-list-page {
    .page-introduce {
      position: relative;

      .ani-list-box {
        display: flex;
      }

      .ani-list-image {
        width: 60%;
        height: auto;
      }

      .ani-list-image2 {
        width: 60%;
        height: auto;
        position: absolute;
        top: 42%;
        left: 0;
      }
    }
  }

  // 네번째 section
  #ani-detail-page {
    .page-introduce {
      position: relative;

      .ani-list-box {
        display: flex;
      }

      .ani-list-image {
        width: 60%;
        height: auto;
      }

      .ani-list-image2 {
        width: 60%;
        height: auto;
        position: absolute;
        top: 42%;
        left: 0;
      }
    }
  }
}
