.page-wrapper {
  position: relative;
  width: 100%;
  margin-top: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .body-content {
    padding-top: 3rem;
    height: 100vh;
    text-align: center;
  }

  h1 {
    font-size: 8rem;
    text-shadow: 2px 2px 2px #ccc, 4px 4px 8px #999;
  }

  .magnifier {
    display: none;
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: none;
    z-index: 10;
    background: url("../../../public/images/Arknights.jpg");
  }
}
