.detail-body {
  width: 100vw;
  height: auto;
  display: flex;
  padding-top: 10rem;
  background-color: white;

  .project-detail-container {
    padding: 1rem;
    transition: margin 0.3s ease;
    width: 60rem;
    border: 1px solid black;

    &.centered {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      height: auto;
    }

    .sidebar {
      position: sticky;
      top: 0;
    }
  }
}
