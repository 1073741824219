.making_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .sorry_image,
  .sorry_image2 {
    border-radius: 12px;
    width: 70%;
  }

  .sorry_image2 {
    display: none;
  }
}
