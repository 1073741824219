.footer {
  width: 100%;
  min-width: 28rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  background-color: #f7f7f6;
  box-shadow: 0px 2px 10px 4px lightGray;

  .final-comment {
    margin: 0 auto;
    text-align: center;
    line-height: 1.6;
  }

  .develop-date {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  h5 {
    color: lightgray;
  }
}
