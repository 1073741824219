.sidebar {
  max-width: 20rem;
  background-color: #3f70e1f1;
  color: #fff;
  padding: 2rem 1rem;
  height: 100vh;
  left: 0;
  top: 0;
  transition: transform 0.3s ease;
  position: sticky;
  z-index: 500;

  &.visible {
    transform: translateX(0);
  }

  &.hidden {
    transform: translateX(-100%);
  }

  ul {
    list-style: none;
    width: 17rem;
    li {
      padding: 1rem 0;
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
      transition: transform 0.3s ease;
      width: 81%;
    }

    li:hover {
      color: pink;
    }

    li.active {
      transform: scale(1.3);
    }
  }

  .show-button {
    padding: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: transparent;
    display: inline-block;
    height: 55px;
    margin-left: 20px;
    margin-top: 55px;
    position: absolute;
    width: 100px;
    top: 50%;
    right: -11.4%;
    transform: rotate(90deg) translateY(0); /*  hover시 자꾸 원래 모양으로 돌아갔는ㄷ ㅔ 회전과 이동을 동시에 적용해야했다. */
    transition: transform 0.3s ease;
  }

  .show-button:before {
    border-bottom: 35px solid pink;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: -35px;
    width: 0;
  }

  .show-button:hover {
    transform: rotate(90deg) translateY(-100%); /* hover시 90도 돌아간 상태로 이동하도록 적용 */
    background-color: #4977e2;
    color: white;
    transition: transform 0.3s ease;
  }

  .close-button {
    margin-right: 1rem;
  }

  .open-button {
    margin-left: 1rem;
    position: absolute;
    right: -5%;
  }
}

@media (max-width: 1200px) {
  .sidebar {
    display: none;
  }
}
