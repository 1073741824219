#projects {
  display: flex;
  flex-direction: column;
  align-items: center;

  .project-select-box {
    display: flex;
    width: 90%;
    justify-content: space-between;
    font-size: 1.6rem;
    color: gray;

    .team-button:hover,
    .intern-button:hover {
      font-weight: 700;
      cursor: pointer;
    }
  }

  .project-card-inner {
    display: flex;
    background-color: #f8f8f8;
    border-radius: 0.625rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 75rem;
    min-width: 34rem;
    overflow: hidden;
    margin: 3rem 0;

    .project-image {
      width: 31.15rem;
      height: auto;
      object-fit: cover;
      padding: 0.625rem;
      margin: auto;
    }

    .project-content {
      padding: 2rem;
      width: 42.6rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        display: flex;
        justify-content: center;
        font-size: 1.75rem;
        font-weight: bold;
      }

      .project-links-detail {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
      }

      .project-button {
        display: inline-block;
        margin-right: 1rem;
        padding: 0.5rem 1rem;
        background-color: #4682b4;
        color: #fff;
        border-radius: 0.3125rem;
        text-decoration: none;
        transition: background-color 0.3s ease;
        cursor: pointer;
      }

      .project-button:hover {
        background-color: #5a9bd5;
      }
    }
  }
}

/* 반응형 스타일 설정 */
@media (max-width: 1200px) {
  .project-card-inner {
    display: flex;
    flex-direction: column;

    .project-content {
      padding: 2rem;

      p {
        text-align: center;
      }
    }
  }
}
