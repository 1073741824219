.introduce-container {
  max-width: 70rem;
  min-width: 28rem;
  margin: 0 auto;
  position: relative;

  section {
    margin: 3rem 0;
  }

  .section-title {
    font-size: 2rem;
  }

  .page-title {
    font-size: 2.4rem;
  }

  .why-box {
    max-width: 60rem;
    min-width: 28rem;
    background-color: #eee;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;

    .think-image {
      width: 250px;
    }

    .think-image2 {
      height: 180px;
      margin-left: 2rem;
    }
  }

  #introduce {
    margin-top: 12rem;
    width: 90%;
    height: auto;
    background-color: white;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
  }

  .introduce-box {
    max-width: 44rem;
    min-width: 24rem;

    .introduce-text {
      font-size: 1.1rem;
      line-height: 1.5;
    }

    .short-intro {
      font-size: 1.4rem;
    }
  }

  .image-card {
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    padding: 1.5rem 1.3rem 4rem 1.3rem;
    box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.3), 5px 5px 0 rgba(0, 0, 0, 0);
    background-color: #eee;

    .profile-image {
      width: 17.5rem;
      height: 18.75rem;
    }
  }

  #my-dream,
  #my-road {
    width: 90%;
    height: auto;
    background-color: white;
    width: 90%;
    height: auto;
    background-color: white;
    padding: 2rem;

    .norm-weight {
      font-weight: 400;
    }

    .text-box {
      line-height: 1.8;
    }
  }

  #my-purpose,
  #my-first-thing,
  #my-solution {
    margin-top: 2rem;
    width: 90%;
    height: auto;
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .detail-text {
    font-size: 1.1rem;
    line-height: 1.7;
  }
}

@media (max-width: 820px) {
  #introduce {
    display: flex;
    flex-direction: column;
    position: relative;

    .image-card {
      position: absolute;
      width: 160px;
      height: 160px;
      background: transparent;
      top: 4%;
      right: 4%;
      padding: 0.5rem;
      border-radius: 50%;

      .profile-image {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
  }
}

@media (max-width: 600px) {
  .image-card {
    visibility: hidden;
  }
}
