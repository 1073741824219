.header {
  background-color: #f7f7f6;
  width: 100vw;
  min-width: 28rem;
  top: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  box-shadow: 0 2px 2px 0 rgba(128, 128, 128, 0.2);

  .category-container {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 2rem 4rem;

    .category-button {
      cursor: pointer;
    }

    .category-button:hover {
      color: orange;
    }
  }

  a {
    text-decoration: none !important;
    font-size: 1.4rem !important;
    transition: color 0.3s ease !important;
    color: inherit !important;
  }

  a:hover {
    color: orange !important;
    font-weight: 700 !important;
  }

  a:focus,
  a:active {
    color: inherit !important;
    outline: none !important;
    text-decoration: none !important;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 50px;

    .nickname {
      padding-left: 4rem;
    }

    ul {
      display: flex;
      justify-content: flex-end;
      list-style-type: none;
      margin: 0;
      padding: 0;
      align-items: center;

      li {
        margin: 0 1.5rem;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1200px) {
  .header .nav ul li {
    margin: 0px 0.5rem;
  }
}
