.main-content {
  padding: 12rem 1rem;
  color: #333;
  max-width: 80rem;
  min-width: 28rem;
  min-height: 100vh;
  margin: 0 auto;

  #introduce {
    background-color: rgb(29, 28, 28);
    border-radius: 1rem;
    color: white;
    min-height: 10rem;
    padding-bottom: 2rem;

    .typeit-wrapper {
      color: white;
      padding: 0 2rem;
    }

    .about-wrapper {
      font-size: 1.2rem;
      padding: 0 2rem;
      line-height: 2;
    }
  }

  .section-title {
    text-align: center;
    font-size: 2.4rem;
  }

  .port-line {
    border: 0;
    height: 0.125rem;
    background: #ccc;
    margin: 2em 0;
  }

  ul {
    margin: 0;
    padding: 1.25rem;
  }

  #about {
    margin-top: 3.125rem;
    margin-bottom: 2rem;
  }

  .typeit-wrapper {
    font-size: 1.875rem;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  .about-wrapper {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1.25rem;
  }
}
