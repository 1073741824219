// #skills {
//   .skills-section {
//     margin-bottom: 2em;

//     .skill-list-title {
//       font-size: 1.8rem;
//     }

//     .skills-list {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 1em;
//       list-style-type: none;
//       padding: 0;
//       background-color: white;
//       border-radius: 1rem;
//       padding: 1rem;
//     }

//     .skill-item {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       border-radius: 50%;
//       overflow: hidden;
//       width: 10rem;
//       height: 10rem;
//       position: relative;
//       transition: transform 0.5s ease;

//       &:hover {
//         transform: scale(1.1);
//         box-shadow: 0 0 20px rgba(255, 208, 121, 0.3);
//       }

//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//       }

//       .java,
//       .vscode {
//         width: 8rem;
//       }

//       .mysql,
//       .postman,
//       .a {
//         height: 7rem;
//       }

//       .intellij,
//       .github {
//         width: 6rem;
//       }

//       .trello,
//       .notion,
//       .slack {
//         height: 6rem;
//         width: 10rem;
//       }
//     }
//   }

//   $color: #2194e0;

//   @keyframes sheen {
//     0% {
//       transform: skewY(-45deg) translateX(0);
//     }
//     100% {
//       transform: skewY(-45deg) translateX(12.5em);
//     }
//   }
// }

#skills {
  .skills-section {
    margin-bottom: 2em;

    .skill-list-title {
      font-size: 1.8rem;
    }

    .skills-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      list-style-type: none;
      padding: 0;
      background-color: white;
      border-radius: 1rem;
      padding: 1rem;
    }

    .skill-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      width: 10rem;
      height: 10rem;
      transition: transform 0.9s ease;

      &:hover {
        transform: scale(1.1);
        // box-shadow: 0 0 20px rgba(255, 208, 121, 0.3);

        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: -30%;
        //   left: -50%;
        //   width: 200%;
        //   height: 200%;
        //   background: linear-gradient(
        //     120deg,
        //     rgba(255, 204, 0, 0.5),
        //     rgba(250, 164, 15, 0)
        //   );
        //   transform: skewY(-45deg);
        //   animation: sheen 1s forwards;
        //   pointer-events: none;
        // }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 100;
      }

      .java,
      .vscode {
        width: 8rem;
      }

      .mysql,
      .postman,
      .a {
        height: 7rem;
      }

      .intellij,
      .github {
        width: 6rem;
      }

      .trello,
      .notion,
      .slack {
        height: 6rem;
        width: 10rem;
      }
    }

    @keyframes sheen {
      0% {
        transform: skewY(-45deg) translateX(-150%);
      }
      100% {
        transform: skewY(-45deg) translateX(150%);
      }
    }
  }
}
